// Necessary if using App Router to ensure this file runs on the client
'use client';

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'b2aafa02-e591-4bf5-9ac1-3c1f0ad932ed',
    clientToken: 'pub908ca0e55fe233d4d922f9e91c9c7d28',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'us3.datadoghq.com',
    service: 'volvo-ev-charger',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.0.1',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

if (datadogRum.getInitConfiguration()) {
    try {
        datadogRum.startSessionReplayRecording();
    } catch (error) {
        console.error('Failed to start session replay recording:', error);
    }
} else {
    console.warn('Datadog RUM is not initialized yet.');
}

export default function DatadogInit() {
    // Render nothing - this component is only included so that the init code
    // above will run client-side
    return null;
}
